window.addEventListener("heyflow-screen-view", (event) => {
    try {
        if (event.detail.screenName === "registration") {
            var ipAddressField = document.getElementById("ipaddress");
            fetch("https://checkip.amazonaws.com/")
            .then((res) => res.text())
            .then((data) => {
                if (ipAddressField) {
                    ipAddressField.value = data.replace("\n", "");
                }
            });
        }
    } catch (err) {}
});

window.addEventListener("heyflow-submit", (event) => {
    // console.log('heyflow submit:', event.detail);
});

window.addEventListener("heyflow-init", (event) => {
    //console.log('heyflow init:', event.detail);
});
document.addEventListener("DOMContentLoaded", function () {
    // Retrieve the company variable from URL or global data
    const urlParams = new URLSearchParams(window.location.search);
    let company = urlParams.get("company") || "default"; // Use 'default' if no company is provided

    // Use only the first word of the company variable
    company = company.split(" ")[0];

    // Define iframe sources for each company
    const content = {
        Naturvel: {
            imprint: "https://www.123-tongain.com/sc/todo=show_html&html_pk=17",
            privacy: "https://www.123-tongain.com/sc/todo=show_html&html_pk=18",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13"
        },
        toleadoo: {
            imprint: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=19",
            privacy: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=20",
            terms: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=16"
        },
        default: {
            imprint: "https://www.123-tongain.com/sc/todo=show_html&html_pk=17",
            privacy: "https://www.123-tongain.com/sc/todo=show_html&html_pk=18",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13"
        }
        };

        // Set iframe sources based on the company variable
        const companyContent = content[company];

        document.getElementById("privacy-content").innerHTML = `<iframe src="${companyContent.privacy}" frameborder="0"></iframe>`;
        document.getElementById("terms-content").innerHTML = `<iframe src="${companyContent.terms}" frameborder="0"></iframe>`;
        document.getElementById("imprint-content").innerHTML = `<iframe src="${companyContent.imprint}" frameborder="0"></iframe>`;

        // Modal functionality
        const modals = {
            privacy: document.getElementById("privacy-modal"),
            terms: document.getElementById("terms-modal"),
            imprint: document.getElementById("imprint-modal")
        };

        document.getElementById("privacy-link").onclick = function () {
            modals.privacy.style.display = "block";
        };

        // If there is no optin checkbox on your flow, delete this part from here...
        document.getElementById("privacy-link-chbx").onclick = function () {
            modals.privacy.style.display = "block";
        };
        //...until here.

        document.getElementById("terms-link").onclick = function () {
            modals.terms.style.display = "block";
        };
        document.getElementById("imprint-link").onclick = function () {
            modals.imprint.style.display = "block";
        };

        document.getElementById("close-privacy").onclick = function () {
            modals.privacy.style.display = "none";
        };

        document.getElementById("close-terms").onclick = function () {
            modals.terms.style.display = "none";
        };
        document.getElementById("close-imprint").onclick = function () {
            modals.imprint.style.display = "none";
        };

        // Close modal if clicked outside of content
        window.onclick = function (event) {
            if (event.target == modals.privacy) {
                modals.privacy.style.display = "none";
            }
            if (event.target == modals.terms) {
                modals.terms.style.display = "none";
            }
            if (event.target == modals.imprint) {
                modals.imprint.style.display = "none";
            }
        };
        // Form validation for the checkbox
        const form = document.querySelector("form"); // Assuming you have a form element
        const checkbox = document.getElementById("optin");
        const errorMessage = document.getElementById("checkbox-error");

        // Handle form submission
        form.addEventListener("submit", function (event) {
            if (!checkbox.checked) {
                // Prevent form submission if checkbox is not checked
                event.preventDefault();
                checkbox.classList.add("error");
                errorMessage.textContent = "Vous devez accepter les conditions pour continuer.";
                errorMessage.classList.add("visible");
            }
        });

        // Clear error when the checkbox is checked
        checkbox.addEventListener("change",
            function () {
                if (checkbox.checked) {
                    // Remove error styles when checkbox is checked
                    checkbox.classList.remove("error");
                    errorMessage.classList.remove("visible");
                }
            });
    });